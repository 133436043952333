import { l, logEvent, qs, tabTrappingHelper } from '@utils/toolbox.js';
import { trackEvent } from '@utils/googleDataLayer';
import { eventCategories, events } from '@js/utils/event';

export class LanguageSelector {
	constructor () {
		// const langSelectorJ = $('.abLanguageSelector');
		const langSelector = qs('.abLanguageSelector');

		if (!langSelector) {
			return;
		}

		langSelector.addEventListener('click', function (e) {
			// Toggle drop down
			if (e.target.closest('.abLanguageSelector__btn')) {
				if (!qs('.abLanguageSelector--expanded')) {
					tabTrappingHelper(qs('.abLanguageSelector__locals'), l('Language selector'), false, 'abLanguageSelector--expanded');
				}

				langSelector.classList.toggle('abLanguageSelector--expanded');

				if (window.innerWidth > 992) {
					qs('.abLanguageSelector__overlay').style.opacity = 0;
				}

				return;
			}

			if (e.target.closest('.abLanguageSelector__local')) {
				const target = e.target.closest('.abLanguageSelector__local');
				if (target.classList.contains('abLanguageSelector__local--disabled')) {
					return false;
				}
				const { val: selected } = target.dataset;

				logEvent(events.changeLanguage, eventCategories.language, target.dataset);

				// collectUserEvent('Click', selected, 'LanguageSelector', 'Select Language');
				trackEvent.triggerGAevent('selectLanguage', selected);
				qs('.abLanguageSelector__local--disabled').classList.remove('abLanguageSelector__local--disabled');
				target.classList.add('abLanguageSelector__local--disabled');

				target.querySelector('a').click();

				e.preventDefault();

				// window.location.reload();
				return;
			}

			if (e.target.closest('.abLanguageSelector__overlay')) {
				if (langSelector.classList.contains('abLanguageSelector--expanded')) {
					qs('.abLanguageSelector__btn').click();
				}
			}
		});
	}
}
